import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Introduction to the shell`}</h1>
    <p>{`I've recently joined `}<a parentName="p" {...{
        "href": "https://includejs.dev/"
      }}>{`Eva Dee from include JS`}</a>{` on a study group of the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCuXy5tCgEninup9cGplbiFw"
      }}>{`Missing Semester`}</a>{` lessons on youtube. The first lesson is all about the shell, this post contains my notes about the first lesson- it's a good, introduction to the shell.`}</p>
    <div className="flex justify-center mt-12">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/Z56Jmr9Z34Q" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </div>
    <p>{`✏️ `}<a parentName="p" {...{
        "href": "https://missing.csail.mit.edu/2020/course-shell/"
      }}>{`Official Notes`}</a></p>
    <h2>{`Introduction`}</h2>
    <p>{`The command `}<inlineCode parentName="p">{`echo`}</inlineCode>{` prints out the given argument to the terminal. `}</p>
    <p><strong parentName="p">{`example`}</strong></p>
    <p><inlineCode parentName="p">{`echo hello`}</inlineCode></p>
    <p>{`will print `}</p>
    <p><inlineCode parentName="p">{`hello`}</inlineCode></p>
    <blockquote>
      <p parentName="blockquote">{`Note: Arguments are separated by white space`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`By default running a command it will try to run on the current directory`}</li>
    </ul>
    <h2>{`Installed programs`}</h2>
    <ul>
      <li parentName="ul">{`Shell uses `}<inlineCode parentName="li">{`environment variables`}</inlineCode>{` to check for programs installed. This variables are set and don't change with each restart of the shell`}</li>
      <li parentName="ul">{`The environment variable responsible to keep a list of all programs installed is called `}<inlineCode parentName="li">{`$PATH`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Path is a list of program paths separated by a colon `}<inlineCode parentName="li">{`:`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`We can check the programs path by running the command `}<inlineCode parentName="li">{`which`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Running `}<inlineCode parentName="li">{`which echo`}</inlineCode>{` will give us `}<inlineCode parentName="li">{`/usr/bin/echo`}</inlineCode>{` on a unix machine`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Absolute paths are paths that fully determine the location of the file`}</li>
      <li parentName="ul">{`Relative Path is the path that is relative to the place where we are.`}
        <ul parentName="li">
          <li parentName="ul">{`If you are inside your documents folder and have a file caled text.txt the relative path of that file is `}<inlineCode parentName="li">{`./text.txt`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <p>{`Since arguments are separated with white space, you need to deal with them in two ways:`}</p>
    <ul>
      <li parentName="ul">{`escape the space with `}<inlineCode parentName="li">{`\\`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`example: `}<inlineCode parentName="li">{`echo Hello\\ World`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`put the name in quotes`}
        <ul parentName="li">
          <li parentName="ul">{`example: `}<inlineCode parentName="li">{`echo "Hello World"`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2>{`Useful Commands`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`mv`}</inlineCode>{` allows you to rename a file or move a file if you give a path to the second argument.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`mv text.txt test.txt`}</inlineCode>{` will rename the file `}<em parentName="li">{`text.txt`}</em>{` to `}<em parentName="li">{`test.txt`}</em></li>
          <li parentName="ul"><inlineCode parentName="li">{`mv test.txt /documents/text.txt`}</inlineCode>{` will move the file into the documents folder and rename it back to `}<em parentName="li">{` text.txt`}</em></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`cp`}</inlineCode>{` allows you to copy a file it takes two arguments - the path and file to copy from and the path to copy to`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`cp text.txt /documents/text.txt`}</inlineCode>{` will copy the file `}<em parentName="li">{`text.txt`}</em>{` to the documents folder`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`rm`}</inlineCode>{` allows you to remove a file`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`rmdir`}</inlineCode>{` allows you to remove a directory if it's empty`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`mkdir`}</inlineCode>{` allows you to create a directory`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`man`}</inlineCode>{` allows you to pass a program name as argument and read the manual about that program`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`man ls`}</inlineCode>{` will give the manual pages of the `}<inlineCode parentName="li">{`ls`}</inlineCode>{` command`}
            <ul parentName="li">
              <li parentName="ul">{`press `}<inlineCode parentName="li">{`q`}</inlineCode>{` to quit the manual pages`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`clear`}</inlineCode>{` or shortcut `}<inlineCode parentName="li">{`ctrl+L`}</inlineCode>{` clears all terminal text and moves prompt back to the top`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cat`}</inlineCode>{` prints the contents of a file`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tail`}</inlineCode>{` prints the last n lines of a file`}
        <ul parentName="li">
          <li parentName="ul">{`We can run `}<inlineCode parentName="li">{`tail -n1`}</inlineCode>{` to print the last line of a file`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`grep`}</inlineCode>{` let's you search for an input string for a given keyword`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tee`}</inlineCode>{` takes a command and writes it to a file but also to stdout (standandard output - the terminal)`}</li>
      <li parentName="ul">{`Command `}<inlineCode parentName="li">{`pwd`}</inlineCode>{` - print working directory - shows full path of the directory we are in`}</li>
      <li parentName="ul"></li>
      <li parentName="ul">{`Command `}<inlineCode parentName="li">{`cd`}</inlineCode>{` -  change current directory - change to a directory`}
        <ul parentName="li">
          <li parentName="ul">{`example: `}<inlineCode parentName="li">{`cd home`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2>{`cd - Change Directory`}</h2>
    <p><strong parentName="p">{`Useful cd arguments`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.`}</inlineCode>{` means current directory`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`..`}</inlineCode>{` means parent directory`}
        <ul parentName="li">
          <li parentName="ul">{`using `}<inlineCode parentName="li">{`cd ..`}</inlineCode>{` moves working directory to the parent folder - one folder up`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`~`}</inlineCode>{` means home directory`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`-`}</inlineCode>{` move to the directory that you were previously in`}</li>
    </ul>
    <h3>{`Example: Moving directories`}</h3>
    <p>{`Let's assume you have the following directory:`}</p>
    <pre><code parentName="pre" {...{}}>{`home
  documents
    pictures
`}</code></pre>
    <p>{`If our `}<inlineCode parentName="p">{`pwd`}</inlineCode>{` is documents, we can move to the home by running the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`cd ..
`}</code></pre>
    <p>{`Now running `}<inlineCode parentName="p">{`pwd`}</inlineCode>{` will give us`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`/home
`}</code></pre>
    <p>{`We can then use the relative path to go back to documents`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`cd ./documents
`}</code></pre>
    <p>{`We can think of these two as `}<inlineCode parentName="p">{`..`}</inlineCode>{` move a directory up and `}<inlineCode parentName="p">{`.`}</inlineCode>{` as move a directory down.`}</p>
    <p>{`Let's assume you are inside the `}<inlineCode parentName="p">{`pictures`}</inlineCode>{` folder, you can go straight to the home folder by running this command`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`cd ../../
`}</code></pre>
    <p>{`You can see that it moved the current  directory first to documents then to home.`}</p>
    <p>{`Since we moved to the `}<em parentName="p">{`home`}</em>{` directory with the previous command, we can run `}<inlineCode parentName="p">{`cd -`}</inlineCode>{` to move back to the `}<em parentName="p">{`pictures`}</em>{` directory. If we run `}<inlineCode parentName="p">{`cd -`}</inlineCode>{` again we will be moved back to the `}<em parentName="p">{`home`}</em>{` directory.`}</p>
    <h2>{`ls- Listing Files in Current Directory`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ls`}</inlineCode>{`  list files in the current directory`}</li>
    </ul>
    <p>{`We can pass flags or options to commands by using a `}<inlineCode parentName="p">{`-`}</inlineCode>{` followed by that flag/option argument.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`flag`}</inlineCode>{` anything that doesn't take a value`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`option`}</inlineCode>{` anything that takes a value`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <p>{`Running `}<inlineCode parentName="p">{`ls -l`}</inlineCode>{` will run the `}<inlineCode parentName="p">{`ls`}</inlineCode>{` command with the `}<inlineCode parentName="p">{`-l`}</inlineCode>{` flag - this command will run the command with a long listing format (it shows permission, owner, group, size, last changed)`}</p>
    <p>{`Let's assume you have the following after running `}<inlineCode parentName="p">{`ls -l`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`drwxr-xr-x 1 FabioRosado FabioRosado 24 Jun 13 12:42 _data
`}</code></pre>
    <ul>
      <li parentName="ul">{`The initial `}<inlineCode parentName="li">{`d`}</inlineCode>{` means that a file is a directory`}</li>
      <li parentName="ul">{`The rest of the letters - `}<inlineCode parentName="li">{`rwxr-xr-x`}</inlineCode>{` - are the permissions`}
        <ul parentName="li">
          <li parentName="ul">{`The first group of letters -`}<inlineCode parentName="li">{`rwxr-`}</inlineCode>{` - are the permissions for the group `}<em parentName="li">{`FabioRosado`}</em></li>
          <li parentName="ul">{`The second group of letters - `}<inlineCode parentName="li">{`xr-`}</inlineCode>{` are permissions for anyone else that is not in the group.`}</li>
          <li parentName="ul">{`The last one is everyone else`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The number after - `}<inlineCode parentName="li">{`1`}</inlineCode>{` - means the permissions of the group`}
        <ul parentName="li">
          <li parentName="ul">{`In this case `}<inlineCode parentName="li">{`1`}</inlineCode>{` means that all files are owned by the `}<em parentName="li">{`FabioRosado`}</em>{` group`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`FabioRosado FabioRosado`}</inlineCode>{` is the user and group `}</li>
      <li parentName="ul">{`The date is the last time the file was modified`}</li>
      <li parentName="ul">{`The final bit, contains the file/folder name`}</li>
    </ul>
    <h2>{`Permissions`}</h2>
    <p>{`Permissions are shown on the shell like the previous example: `}<inlineCode parentName="p">{`rwxr-xr-x`}</inlineCode></p>
    <blockquote>
      <p parentName="blockquote">{`Note:  they will always follow the pattern `}<inlineCode parentName="p">{`rwx`}</inlineCode>{`.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`r`}</inlineCode>{` means reading permission`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`w`}</inlineCode>{` means writing permission`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`x`}</inlineCode>{` means executing permission`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`-`}</inlineCode>{` means you don't have that permission`}</li>
    </ul>
    <p>{`For directories things are a bit diferent:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`r`}</inlineCode>{` means if you are allowed to see which files are inside the directory`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`w`}</inlineCode>{` means if you are allowed to rename, create or remove within the directory`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`x`}</inlineCode>{` means if you are allowed to enter the directory`}
        <ul parentName="li">
          <li parentName="ul">{`to `}<inlineCode parentName="li">{`cd`}</inlineCode>{` into the directory you must have the `}<inlineCode parentName="li">{`x`}</inlineCode>{` permission set on all parent directories.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`We can change the permissions of a file with the command `}<inlineCode parentName="p">{`chmod`}</inlineCode>{`.`}</p>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`chmod u+x file
Give the `}{`[u]`}{`ser who owns a file the right to e`}{`[x]`}{`ecute it`}</li>
      </ul>
      <p parentName="blockquote">{`From `}<a parentName="p" {...{
          "href": "https://includejs.dev/notes/missing-semester-01/"
        }}>{`IncludeJS - Eva Dee Notes`}</a></p>
    </blockquote>
    <h2>{`Streams: Combining Programs`}</h2>
    <p>{`In shell you can combine programs by chaining commands, interacting with files, etc.`}</p>
    <ul>
      <li parentName="ul">{`Every program has at least two separated streams`}
        <ul parentName="li">
          <li parentName="ul">{`Input stream`}</li>
          <li parentName="ul">{`output stream`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`>`}</inlineCode>{` rewire the output of this program to the file on the right`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<`}</inlineCode>{` rewire the output of the file on the right into the left program`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`>>`}</inlineCode>{` append output of the program to the right file`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`|`}</inlineCode>{` takes the output of the program to the left and make it the input of the program on the right`}</li>
    </ul>
    <h3>{`Example: Using the rewire operators`}</h3>
    <p>{`Running this command will create a file with the contents of the command `}<inlineCode parentName="p">{`echo hello`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`echo hello > hello.txt
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note: Nothing will be printed because the output of the command `}<inlineCode parentName="p">{`echo hello`}</inlineCode>{` is being added to the `}<em parentName="p">{`hello.txt`}</em>{` file`}</p>
    </blockquote>
    <p>{`We can check that the contents of `}<em parentName="p">{`hello.txt`}</em>{` do contain the word `}<strong parentName="p">{`hello`}</strong>{` by running the command`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat hello.txt
hello
`}</code></pre>
    <p>{`We could combine the two operators (`}<inlineCode parentName="p">{`>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<`}</inlineCode>{` ) to copy the contents of a file `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`cat < hello.txt > hey.txt
`}</code></pre>
    <p>{`This means that we are`}</p>
    <ul>
      <li parentName="ul">{`Opening hello.txt and pass it to the `}<inlineCode parentName="li">{`cat`}</inlineCode>{` command, which will output the text `}<em parentName="li">{`hello`}</em></li>
      <li parentName="ul">{`Use that `}<em parentName="li">{`hello`}</em>{` text to input it back to a file called `}<inlineCode parentName="li">{`hey.txt`}</inlineCode></li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Note: The `}<inlineCode parentName="p">{`cat`}</inlineCode>{` command doesn't know about the redirection to the `}<inlineCode parentName="p">{`hey.txt`}</inlineCode>{` file - the shell handles everything`}</p>
    </blockquote>
    <p>{`Now running the command `}<inlineCode parentName="p">{`cat hey.txt`}</inlineCode>{` we can see that we get back `}<em parentName="p">{`hello`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat hey.txt
hello
`}</code></pre>
    <p>{`If we want to append another `}<em parentName="p">{`hello`}</em>{` to our file we can use the append operator`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo hello >> hey.txt
$ cat hey.txt
hello
hello
`}</code></pre>
    <h3>{`Example: Using the pipe operator`}</h3>
    <p>{`We could use the pipe operator to get the last line of the command `}<inlineCode parentName="p">{`ls -l`}</inlineCode>{` by running the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`ls -l | tail -n1
`}</code></pre>
    <p>{`We can also write the output of that last line into a file by combining both operators`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`ls -l | tail -n1 > ls.txt
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      